import { lazy } from 'react';

import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

const DashboardDefault = Loadable(lazy(() => import('pages/admin-dashboard')));
const Customers = Loadable(lazy(() => import('pages/customers')));
const Destination = Loadable(lazy(() => import('pages/destination')));
const AddDestination = Loadable(lazy(() => import('pages/add-destination')));
const ViewDestination = Loadable(lazy(() => import('pages/view-destination')));
const CustomerProfile = Loadable(lazy(() => import('pages/customer-profile')));
const Tasks = Loadable(lazy(() => import('pages/admin-tasks')));
const AddTask = Loadable(lazy(() => import('pages/admin-add-task')));
const Track = Loadable(lazy(() => import('pages/admin-track')));
const TaskView = Loadable(lazy(() => import('pages/admin-view-task')));
const Tickets = Loadable(lazy(() => import('pages/admin-tickets')));
const TicketView = Loadable(lazy(() => import('pages/admin-view-ticket')));
const TaskReport = Loadable(lazy(() => import('pages/admin-task-report')));
const RevenueReport = Loadable(
  lazy(() => import('pages/admin-revenue-report'))
);
const TripReport = Loadable(lazy(() => import('pages/admin-trip-report')));

const MainRoutes = {
  path: '/admin',
  element: <MainLayout />,
  children: [
    {
      path: '/admin/dashboard',
      element: <DashboardDefault />
    },
    {
      path: '/admin/dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: '/admin/dashboard/customers/:status',
      element: <Customers />
    },
    {
      path: '/admin/dashboard/customer/profile/:id',
      element: <CustomerProfile />
    },
    {
      path: '/admin/dashboard/tasks/:status',
      element: <Tasks />
    },
    {
      path: '/admin/dashboard/task/view/:id',
      element: <TaskView />
    },
    {
      path: '/admin/dashboard/query/:status',
      element: <Tickets />
    },
    {
      path: '/admin/dashboard/query/view/:id',
      element: <TicketView />
    },
    {
      path: '/admin/dashboard/report/task',
      element: <TaskReport />
    },
    {
      path: '/admin/dashboard/report/revenue',
      element: <RevenueReport />
    },
    {
      path: '/admin/dashboard/track',
      element: <Track />
    },
    {
      path: '/admin/dashboard/task/add',
      element: <AddTask />
    },
    {
      path: '/admin/dashboard/destination',
      element: <Destination />
    },
    {
      path: '/admin/dashboard/destination/add',
      element: <AddDestination />
    },
    {
      path: '/admin/dashboard/destination/:id',
      element: <ViewDestination />
    },
    {
      path: '/admin/dashboard/report/logistics',
      element: <TripReport />
    }
  ]
};

export default MainRoutes;
