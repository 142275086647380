export const SUCCESS_UPLOAD_DOCUMENT = 'Document Uploaded Successfully';
export const SUCCESS_DELETE_DOCUMENT = 'Document Deleted Successfully';
export const SUCCESS_CHANGED_PASSWORD = 'Password Changed Successfully';
export const SUCCESS_UPDATED_PROFILE = 'Profile Updated Successfully';
export const SUCCESS_DETELE_SITE = 'Site Deleted Successfully';

export const SUCCESS_SEND_MSG = 'Message Sent Successfully';
export const SUCCESS_DELETE_MSG = 'Message Deleted Successfully';

export const SUCCESS_CREATE_TICKET = 'Ticket Created Successfully';
export const SUCCESS_UPDATE_TICKET_STATUS =
  'Ticket Status Updated Successfully';
export const SUCCESS_UPDATE_TICKET = 'Ticket Updated Successfully';
export const SUCCESS_CLOSE_TICKET = 'Ticket Closed Successfully';
export const SUCCESS_CONTACT_US = 'Your Query Sent Successfully';

export const SUCCESS_ACCEPT_TASK_QUOTE = 'Quote Accepted Successfully';
export const SUCCESS_NEGOTIATE_TASK_QUOTE = 'Quote Negotiated Successfully';
export const SUCCESS_DECLINED_TASK_QUOTE = 'Quote Declined Successfully';
export const SUCCESS_UPDATE_TASK = 'Task Updated Successfully';
export const SUCCESS_CREATE_TASK = 'Task Created Successfully';
export const SUCCESS_UPDATE_TASK_STATUS = 'Task Status Updated Successfully';
export const SUCCESS_ADD_PROCESSED_LIST = 'Processed Task Added Successfully';
export const SUCCESS_UPDATE_TASK_STATUS_ABANDONED =
  'Task Abandoned Successfully';
export const SUCCESS_SEND_QUOTE = 'Quote Sent Successfully';
export const SUCCESS_DELETE_PROCESSED_ENTRY =
  'Processed Entry Deleted Successfully';

export const SUCCESS_ADD_TRUCK = 'Truck Added Successfully';
export const SUCCESS_UPDATE_TRUCK = 'Truck Updated Successfully';
export const SUCCESS_DELETE_TRUCK = 'Truck Deleted Successfully';
export const SUCCESS_NO_TRUCK_TRACK_DATA = 'No Truck Tracking Data Found';

export const ERROR_UPLOAD_DOCUMENT = 'Error while uploading documents';
export const ERROR_DELETE_DOCUMENT = 'Error while deleting documents';
export const ERROR_DOWNLOAD_DOCUMENT = 'Error while downloading documents';
export const ERROR_ONBOARD_DOC_DOCUMENT = 'Error while getting onboarding doc';

export const ERROR_GET_DASHBOARD_KPIS = 'Error while getting KPIS';
export const ERROR_GET_DASHBOARD_DATA_POINTS =
  'Error while getting data points';

export const ERROR_GET_ONBOARD_MSG = 'Error while getting OnBoard Msg';
export const ERROR_SEND_MSG = 'Error while sending Msg';
export const ERROR_DELETE_MSG = 'Error while deleting Msg';

export const ERROR_GET_TICKET_LIST = 'Error while getting ticket list';
export const ERROR_GET_TICKET = 'Error while getting ticket details';
export const ERROR_GET_TICKET_DOC_MSG = 'Error while getting ticket doc & msg';
export const ERROR_UPDATE_TICKET_STATUS = 'Error while updating ticket status';

export const ERROR_GET_TASK_LIST = 'Error while getting task list';
export const ERROR_GET_TASK = 'Error while getting task details';
export const ERROR_DECLINED_TASK_QUOTE = 'Error while declining task quote';
export const ERROR_CREATE_TASK = 'Error while creating task';
export const ERROR_UPDATE_TASK_STATUS = 'Error while updating task status';
export const ERROR_SEND_TASK_QUOTE = 'Error while sending task quote';
export const ERROR_GET_PROCESSED_LIST =
  'Error while getting task processed list';
export const ERROR_ADD_PROCESSED_LIST =
  'Error while adding task processed list';
export const ERROR_DELETE_PROCESSED_LIST =
  'Error while deleting processed entry';

export const ERROR_GET_TRUCK_DETAILS = 'Error while getting truck details';
export const ERROR_ADD_TRUCK = 'Error while adding truck details';
export const ERROR_UPDATE_TRUCK = 'Error while updating truck details';
export const ERROR_DELETE_TRACKING = 'Error while deleting truck details';
export const ERROR_GET_TRUCK_TRACKING =
  'Error while getting truck tracking data';

export const ERROR_GET_CUSTOMER_LIST = 'Error while getting customer list';
export const ERROR_GET_DESTINATION_LIST =
  'Error while getting destination list';
export const ERROR_GET_ALL_TRUCKS_LIST = 'Error while getting all trucks list';
export const ERROR_GET_TRUCKS_LIST_COUNT =
  'Error while getting trucks list count';
export const ERROR_GET_DESTINATION = 'Error while getting destination';
export const ERROR_DELETE_DESTINATION = 'Error while deleting destination';
export const ERROR_GET_CUSTOMER = 'Error while getting customer details';
export const ERROR_UPDATE_CUSTOMER = 'Error while updating customer details';

export const ERROR_GET_SITE = 'Error while getting site';
export const ERROR_DELETE_SITE =
  'Error deleting Site, perhaps site is being referenced in an existing task.';
export const ERROR_GET_TASK_SITE_DETAILS = 'Error while getting site details';

export const ERROR_CHANGED_PASSWORD = 'Error while updating password';

export const ERROR_GET_TASK_DOC_MSG = 'Error while getting task doc & msg';
export const ERROR_GET_TASK_DOC = 'Error while getting task documents';
export const ERROR_ACCEPT_TASK_QUOTE = 'Error while accepting task quote';
export const ERROR_NEGOTIATE_TASK_QUOTE = 'Error while negotiating task quote';

export const ERROR_GET_NOTIFICATION = 'Error while getting notification';

export const ERROR_TASK_REPORT = 'Error while getting task report';

export const ERROR_PROFILE_MOBILE =
  'Mobile OTP number already in use OR data entered is not valid. Call us for any support.';

export const ERROR_CREATE_DESTINATION = 'Error while creating destination';
export const ERROR_UPDATE_DESTINATION = 'Error while updating destination';

export const SUCCESS_CREATE_DESTINATION = 'Destination Created Successfully';
export const SUCCESS_UPDATE_DESTINATION = 'Destination Updated Successfully';
export const SUCCESS_DELETE_DESTINATION = 'Destination Deleted Successfully';
