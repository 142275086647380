export const API_GET_PIN = '/getpin';
export const API_GET_GSTIN = '/getgstin';

export const API_UPDATE_FRONTEND = '/customer/update_frontend';
export const API_TRY_LOGIN = '/customer/try_login';
export const API_TRY_REGISTRATION_LOGIN = '/customer/try_registration_login';
export const API_CREATE_LOGIN = '/customer/create_login';
export const API_OTP_VERIFY = '/customer/verify_otp';
export const API_REGISTER_CUSTOMER = '/customer/register_customer';
export const API_GET_CUSTOMER = '/customer/get_customer';
export const API_GET_ONBOARDING_DOCUMENTS =
  '/customer/get_onboarding_documents';
export const API_GET_ONBOARDING_MSG = '/customer/get_onboarding_msg';
export const API_REGISTER_CUSTOMER_UPLOAD =
  '/customer/register_customer_upload';
export const API_DELETE_ONBOARDING_DOC = '/customer/delete_onboarding_doc';
export const API_GET_DOC_N_MSG = '/customer/get_documents_n_msgs';
export const API_GET_DOCS = '/customer/get_documents';
export const API_GET_DELETE_DOC = '/customer/delete_documents';
export const API_DELETE_TASK_DOC = '/customer/delete_task_doc';
export const API_GET_ADD_MSG_TO_TASK = '/customer/add_msg_to_task';
export const API_TASK_UPLOAD = '/customer/upload';
export const API_ADD_TASK = '/customer/add_task';
export const API_UPDATE_TASK = '/customer/update_task';
export const API_ACCEPT_QUOTE_TASK = '/customer/accept_quote_task';
export const API_DECLINE_QUOTE_TASK = '/customer/decline_quote_task';
export const API_UPDATE_CUSTOMER = '/customer/update_customer';
export const API_ADD_SITE = '/customer/add_site';
export const API_EDIT_SITE = '/customer/edit_site';
export const API_DELETE_SITE = '/customer/delete_site';
export const API_GET_SITES = '/customer/get_sites';
export const API_GET_TASKS = '/customer/get_tasks';
export const API_TASK_DOWNLOAD = '/customer/download';
export const API_RESET_PASSWORD = '/customer/reset_pass';
export const API_GET_TERMS = '/customer/get_terms';
export const API_GET_NOTIFICATION = '/customer/get_notifications';
export const API_GET_TRUCK_FOR_TASK = '/customer/get_truck_for_task';
export const API_GET_TASK = '/customer/get_task';
export const API_GET_TICKETS = '/customer/get_tickets';
export const API_CREATE_TICKET = '/customer/create_ticket';
export const API_GET_TICKET = '/customer/get_ticket';
export const API_CLOSE_TICKET = '/customer/update_ticket_status';
export const API_GET_TICKET_DOCS_N_MSG = '/customer/get_docs_n_msgs_for_ticket';
export const API_GET_ADD_MSG_TO_TICKET = '/customer/add_msg_to_ticket';
export const API_UPLOAD_TICKET_DOC = '/customer/upload_ticket_doc';
export const API_DOWNLOAD_TICKET_DOC = '/customer/download_ticket_doc';
export const API_DELETE_TICKET_DOC = '/customer/delete_ticket_doc';
export const API_DOWNLOAD_TASK_ZIP = '/customer/download_task_zip';
export const API_DASHBOARD_KPIS = '/customer/get_dashboard_kpis';
export const API_CONTACT_US = '/customer/contact_us';
export const API_GET_TASK_REPORT = '/customer/report_get_all_tasks';
export const API_VIEW_DOCUMENT = '/customer/view_doc';
export const API_GET_TRUCK_MAP = '/customer/get_truck_map_data';
export const API_GET_TRUCK_TRACKING = '/customer/download_truck_tracking_data';
export const API_FORGOT_PASSWORD = '/customer/forget_password';
export const API_SET_NEW_PASSWORD = '/customer/set_new_pass';
export const API_VERIFY_PASS_PHRASE = '/customer/verify_passphrase';
export const API_GENERATE_MOBILE_OTP = '/customer/generate_mobile_login_otp';
export const API_VERIFY_MOBILE_OTP = '/customer/verify_mobile_login_otp';
export const API_EMAIL_RESEND_OTP = '/customer/resend_email_login_otp';
export const API_NEGOTITAE_QUOTE_TASK = '/customer/negotiate_quote_task';
export const API_GET_DESTINATIONS = '/customer/get_destinations';
export const API_GET_TRUCKS_COUNT = '/customer/get_all_trucks_count';
export const API_GET_ALL_TRUCKS_LIST = '/customer/get_all_trucks';
export const API_ADD_TRUCK = '/customer/add_truck';
export const API_UPDATE_TRUCK = '/customer/update_truck';
export const API_DELETE_TRUCK = '/customer/delete_truck';
export const API_GET_TASK_PROCESSED_LIST =
  '/customer/list_processed_entry_to_task';
export const API_RE_GENERATE_MOBILE_OTP =
  '/customer/re_generate_mobile_login_otp';

export const API_ADMIN = '/admin';
export const API_ADMIN_LOGIN = '/admin/admin_login';
export const API_ADMIN_OTP = '/admin/admin_verify_otp';
export const API_ADMIN_GET_ALL_CUST = '/admin/admin_get_all_cust';
export const API_ADMIN_GET_CUST = '/admin/admin_get_cust';
export const API_ADMIN_UPDATE_ONBOARD_MSG = '/admin/update_onboarding_msg';
export const API_ADMIN_ONBOARDING_DOCUMENTS = '/admin/get_onboarding_docs';
export const API_ADMIN_DELETE_ONBOARDING_DOC = '/admin/delete_onboarding_doc';
export const API_ADMIN_UPLOAD_ONBOARD_DOC = '/admin/register_customer_upload';
export const API_ADMIN_ONBOARDING_MSG = '/admin/get_onboarding_msg';
export const API_SUSPEND_CUSTOMER = '/admin/suspend_customer';
export const API_APPROVE_CUSTOMER = '/admin/approve_customer';
export const API_ADMIN_UPDATE_CUSTOMER = '/admin/update_customer';
export const API_ADMIN_DOWNLOAD = '/admin/download';
export const API_ADMIN_DASHBOARD_KPIS = '/admin/get_dashboard_kpis';
export const API_ADMIN_ADD_TASK = '/admin/add_task';
export const API_ADMIN_GET_ALL_TASKS = '/admin/get_all_tasks_all_cust_range';
export const API_ADMIN_GET_ALL_TASKS_FOR_CUST = '/admin/get_tasks_for_cust';
export const API_ADMIN_GET_PENDING_TASKS = '/admin/get_tasks_to_reply';
export const API_ADMIN_GET_TASK = '/admin/admin_get_task';
export const API_ADMIN_TASK_DOCS_N_MSG = '/admin/get_documents_n_msgs';
export const API_ADMIN_TASK_SITE = '/admin/get_sites_for_task';
export const API_ADMIN_TASK_UPLOAD_DOC = '/admin/upload_task_doc';
export const API_ADMIN_TASK_DELETE_DOC = '/admin/delete_task_doc';
export const API_ADMIN_DOWNLOAD_DOC = '/admin/download';
export const API_ADMIN_UPDATE_TASK = '/admin/update_task';
export const API_ADMIN_ADD_TRUCK = '/admin/add_truck';
export const API_ADMIN_UPDATE_TRUCK = '/admin/update_truck';
export const API_ADMIN_DELETE_TRUCK = '/admin/delete_truck';
export const API_ADMIN_TRUCK_UPLOAD_DOC = '/admin/upload_truck_doc';
export const API_ADMIN_GET_TRUCK_MAP = '/admin/get_truck_map_data';
export const API_ADMIN_ADD_MSG_TO_TASK = '/admin/add_msg_to_task';
export const API_ADMIN_UPDATE_TASK_STATUS = '/admin/admin_update_task_status';
export const API_ADMIN_GET_TASK_TRUCK = '/admin/get_truck_for_task';
export const API_ADMIN_GET_ALL_TICKETS = '/admin/get_all_tickets_all_cust';
export const API_ADMIN_GET_TICKET = '/admin/get_ticket';
export const API_ADMIN_SEND_MSG_TICKET = '/admin/add_msg_to_ticket';
export const API_ADMIN_UPDATE_TICKET = '/admin/update_ticket';
export const API_ADMIN_UPDATE_STATUS_TICKET = '/admin/update_ticket_status';
export const API_ADMIN_UPLOAD_TICKET_DOC = '/admin/upload_ticket_doc';
export const API_ADMIN_DOWNLOAD_TICKET_DOC = '/admin/download_ticket_doc';
export const API_ADMIN_DELETE_TICKET_DOC = '/admin/delete_ticket_doc';
export const API_ADMIN_DOWNLOAD_TASK_ZIP = '/admin/download_task_zip';
export const API_ADMIN_GET_SITE_OF_CUSTOMER = '/admin/get_sites_for_customer';
export const API_ADMIN_VIEW_DOCUMENT = '/admin/view_doc';
export const API_ADMIN_GET_TASK_REPORT = '/admin/report_get_all_tasks';
export const API_ADMIN_GET_REVENUE_REPORT = '/admin/report_get_revenue';
export const API_ADMIN_GET_DESTINATIONS = '/admin/get_destinations';
export const API_GET_DESTINATION = '/admin/get_destination';
export const API_ADD_DESTINATION = '/admin/add_destination';
export const API_UPDATE_DESTINATION = '/admin/update_destination';
export const API_DELETE_DESTINATION = '/admin/archive_destination';
export const API_GET_ALL_TRUCKS = '/admin/get_all_trucks';
export const API_ADMIN_GET_TRUCKS_COUNT = '/admin/get_all_trucks_count';
export const API_ADMIN_GET_TRUCK_TRACKING =
  '/admin/download_truck_tracking_data';
export const API_ADMIN_QUOTE_TASK = '/admin/quote_task';
export const API_ADMIN_GET_TASK_DATA_POINTS =
  '/admin/report_get_tasks_data_points';
export const API_ADMIN_GET_REVENUE_DATA_POINTS =
  '/admin/report_get_revenue_data_points';
export const API_GET_PENDIND_CUST_LIST =
  '/admin/admin_get_all_cust_pending_approval';
export const API_GET_SUSPENDED_CUST_LIST =
  '/admin/admin_get_all_cust_suspended';
export const API_GET_REGISTERED_CUST_LIST =
  '/admin/admin_get_all_cust_pending_registration';
export const API_ADMIN_GET_TICKET_DOCS_N_MSG =
  '/admin/get_docs_n_msgs_for_ticket';
export const API_ADMIN_GET_TASK_PROCESSED_LIST =
  '/admin/list_processed_entry_to_task';
export const API_ADMIN_ADD_PROCESSED_LIST =
  '/admin/add_processed_entry_to_task';
export const API_ADMIN_DELETE_PROCESSED_LIST =
  '/admin/delete_processed_entry_to_task';

export const STATES = [
  'ANDHRA PRADESH',
  'ARUNACHAL PRADESH ',
  'ASSAM',
  'BIHAR',
  'CHHATTISGARH',
  'GOA',
  'GUJARAT',
  'HARYANA',
  'HIMA    CHAL PRADESH',
  'JAMMU AND KASHMIR',
  'JHARKHAND',
  'KARNATAKA',
  'KERALA',
  'MADHYA PRADESH',
  'MAHARASHTRA',
  'MANIPUR',
  'MEGHALAYA',
  'MIZORAM',
  'NAGALAND',
  'ODISHA',
  'ODISSA',
  'PUNJAB',
  'RAJASTHAN',
  'SIKKIM',
  'TAMIL NADU',
  'TELANGANA',
  'TRIPURA',
  'UTTAR PRADESH',
  'UTTARAKHAND',
  'WEST BENGAL',
  'ANDAMAN AND NICOBAR ISLANDS',
  'CHANDIGARH',
  'DADRA AND NAGAR HAVELI',
  'DAMAN AND DIU',
  'LAKSHADWEEP',
  'NATIONAL CAPITAL TERRITORY OF DELHI',
  'DELHI',
  'PUDUCHERRY',
  'PONDICHERRY'
];

export const TRY_REGISTRATION_ERROR = {
  401: 'Incorrect Username or Password.',
  403: ' Your account is already registered/approved. Please try to login.',
  405: 'Your account is suspended, please contact us on our support number.',
  410: 'Unknown Exception or error, Please contact us on our support number.',
  421: 'Unknown Exception or error, Please contact us on our support number.'
};

export const REGISTER_CUSTOMER_ERROR = {
  418: 'This mobile number is already registered, please use a different mobile number or try and login with the registered mobile number'
};

export const SIGNUP_ERROR = {
  401: 'Unauthorised',
  420: 'Password complexity not as expected',
  405: 'User already exists, Please try to login',
  415: 'Email ID Incorrect format',
  410: 'Server Error'
};

export const EMAIL_LOGIN_ERROR = {
  410: 'User/Mobile Does not exist',
  403: 'User is pending Approval from the Admin, you shall receive an email once approved',
  404: 'User is yet to complete the registration',
  405: 'User is suspended by Admin',
  401: 'Authentication Error',
  201: 'User is pending Approval from Admin'
};

export const MOBILE_LOGIN_ERROR = {
  421: 'Exception Occurred, Please contact us on our support number.',
  410: 'Mobile Number entered is not registered or invalid.',
  405: 'Your account is suspended, please contact us on our support number.',
  403: 'Admin is yet to approve your account, please contact us on our support number.',
  404: 'You are yet to complete your registration, pleas contact us on our support number.'
};

export const PASSWORD_REQUIREMENTS = [
  'At least 8 characters',
  'At least 1 lower letter (a-z)',
  'At least 1 uppercase letter (A-Z)',
  'At least 1 number (0-9)',
  'At least 1 special characters'
];

export const OTP_LOGIN_ERROR = {
  401: 'Authentication error, Incorrect OTP',
  403: 'User is already registered',
  405: 'User is suspended',
  410: 'Please wait for 5 minutes & try login again.'
};

export const TASK_STATUS_COLOR = {
  CREATED: '#2196f3',
  QUOTED: '#00695f',
  ACCEPTED: '#ffb74d',
  INPROGRESS: '#a1887f',
  PROCESSED: '#64b5f6',
  PENDINGPAYMENT: '#f73378',
  COMPLETED: '#2c387e',
  ABANDONED: '#ab003c',
  UNSERVICED: '#f50057',
  QUOTE_DECLINED: '#ff3d00',
  NEGOTIATE: '#b26500'
};

export const TASK_STATUS = [
  'CREATED',
  'QUOTED',
  'ACCEPTED',
  'INPROGRESS',
  'PROCESSED',
  'COMPLETED',
  'ABANDONED',
  'QUOTE_DECLINED',
  'NEGOTIATE'
];

export const TICKET_PRIORITY = ['NORMAL', 'URGENT', 'ACCOUNTS'];

export const CONTACT_US_PRIORITY = ['NORMAL', 'URGENT'];

export const TICKET_STATUS = ['CREATED', 'ONADMIN', 'RESPONDED', 'CLOSED'];

export const TICKET_STATUS_COLOR = {
  CREATED: '#2196f3',
  ONADMIN: '#ba68c8',
  RESPONDED: '#ffb74d',
  CLOSED: '#a1887f'
};

export const TICKET_PRIORITY_COLOR = {
  NORMAL: '#2196f3',
  URGENT: '#00695f',
  ACCOUNTS: '#ffb74d'
};

export const TRUCK_STATUS_COLOR = {
  SCHEDULED: '#2196f3',
  ARRIVED: '#dce775',
  INPROGRESS: '#ffb74d',
  COMPLETED: '#a1887f',
  DELAYED: '#64b5f6',
  CANCELLED: '#ba68c8'
};

export const MATERIAL_TYPE_COLOR = {
  OTHER: '#4caf50',
  EWASTE: '#ff5722',
  MUNICIPAL: '#2196f3',
  PLASTIC: '#009688',
  CONSTRUCTION: '#ffb74d'
};

export const CUSTOMER_STATUS_COLOR = {
  CREATED: '#2196f3',
  APPROVED: '#4caf50',
  SUSPENDED: '#f44336',
  REGISTERED: 'rgb(102, 60, 0)'
};

export const CUSTOMER_KYC_DOC = [
  {
    title: 'PAN Card',
    doc_name: 'panCard'
  },
  {
    title: 'GSTIN Registration',
    doc_name: 'gstRegistration'
  },
  {
    title: 'Aadhar / Voter',
    doc_name: 'userId'
  },
  {
    title: 'Entity Registration',
    doc_name: 'entityRegistration'
  },
  {
    title: 'Cancelled Cheque',
    doc_name: 'cancelledCheque'
  }
];

export const CUSTOMER_KYC_DOC_ENUM = {
  panCard: 'PAN',
  gstRegistration: 'GST',
  userId: 'UserId',
  entityRegistration: 'Entity',
  cancelledCheque: 'CHEQUE'
};

export const TASK_WASTE_MATERIAL = [
  {
    title: 'Waste Material Image 1',
    doc_name: 'Image1'
  },
  {
    title: 'Waste Material Image 2',
    doc_name: 'Image2'
  },
  {
    title: 'Waste Material Image 3',
    doc_name: 'Image3'
  }
];

export const TASK_WASTE_MATERIAL_ENUM = {
  'wasteMaterial-Image1': 'wasteMaterial-Image1',
  'wasteMaterial-Image2': 'wasteMaterial-Image2',
  'wasteMaterial-Image3': 'wasteMaterial-Image3'
};

export const MATERIAL_LIST = [
  {
    label: 'PLASTIC',
    value: 'PLASTIC'
  },
  {
    label: 'EWASTE',
    value: 'EWASTE'
  },
  {
    label: 'CONSTRUCTION',
    value: 'CONSTRUCTION'
  },
  {
    label: 'MUNICIPAL',
    value: 'MUNICIPAL'
  },
  {
    label: 'OTHER',
    value: 'OTHER'
  }
];

export const TASK_DOC = [
  {
    title: 'GST Docs',
    doc_name: 'gstDocs'
  },
  {
    title: 'Disposal Certificate',
    doc_name: 'disposalCertificate'
  },
  {
    title: 'Invoice - Disposal',
    doc_name: 'invoiceDisposal'
  }
];

export const TRANSPORT_DOC = [
  {
    title: 'EWay Bill',
    doc_name: 'ewayBill'
  },
  {
    title: 'WB Slip',
    doc_name: 'wbSlip'
  },
  {
    title: 'Goods Receipt',
    doc_name: 'goodsReceipt'
  }
];

export const TASK_DOC_ENUM = {
  'account-gstDocs': 'GST',
  'account-disposalCertificate': 'Certificate',
  'account-invoiceDisposal': 'Invoice'
};

export const WEIGHT_UNITS = [
  {
    label: 'TON',
    value: 'TON'
  }
];

export const REQUEST_TYPE = [
  {
    label: 'BUY',
    value: 'BUY'
  },
  {
    label: 'SELL',
    value: 'SELL'
  },
  {
    label: 'DISPOSE',
    value: 'DISPOSE'
  }
];

export const TASK_NON_STEPPER = ['COMPLETED', 'ABANDONED', 'UNSERVICED'];

export const REPORT_FILTER_DATE = [
  {
    label: 'Last 30 days',
    value: 30
  },
  {
    label: 'Last 90 days',
    value: 90
  },
  {
    label: 'Last 365 days',
    value: 365
  },
  {
    label: 'Custom',
    value: 'custom'
  }
];

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const TICKET_FILTER_DATE = [
  {
    label: 'Last 7 days',
    value: 7
  },
  {
    label: 'Last 30 days',
    value: 30
  },
  {
    label: 'Last 90 days',
    value: 90
  },
  {
    label: 'Custom',
    value: 'custom'
  }
];

export const DATA_POINTS_OPTIONS = [
  {
    label: 'Last 1 month',
    value: 1
  },
  {
    label: 'Last 3 month',
    value: 3
  },
  {
    label: 'Last 6 month',
    value: 6
  },
  {
    label: 'Last 9 month',
    value: 9
  },
  {
    label: 'Last 12 month',
    value: 12
  }
];

export const DATA_POINTS_STATUS = [
  {
    label: 'BOTH',
    value: 'BOTH'
  },
  {
    label: 'COMPLETED',
    value: 'COMPLETED'
  },
  {
    label: 'INPROGRESS',
    value: 'INPROGRESS'
  }
];

export const KPIS_COLOR_CODE = ['#2196f3', '#009688', '#5d4037', '#ff5722'];

export const ENTITY_TYPE = [
  {
    label: 'MATERIAL RECOVERY FACILITY',
    value: 'MATERIAL_RECOVERY_FACILITY'
  },
  {
    label: 'WASTE AGGREGATOR',
    value: 'WASTE_AGGREGATOR'
  },
  {
    label: 'NGO',
    value: 'NGO'
  },
  {
    label: 'WASTE GENERATOR',
    value: 'WASTE_GENERATOR'
  },
  {
    label: 'MANUFACTURING INDUSTRY',
    value: 'MANUFACTURING_INDUSTRY'
  },
  {
    label: 'RECYCLING INDUSTRY',
    value: 'RECYCLING_INDUSTRY'
  },
  {
    label: 'PIBO',
    value: 'PIBO'
  },
  {
    label: 'REAL ESTATE',
    value: 'REAL_ESTATE'
  },
  {
    label: 'COMMERCIAL ESTABLISHMENT',
    value: 'COMMERCIAL_ESTABLISHMENT'
  },
  {
    label: 'FMCG',
    value: 'FMCG'
  },
  {
    label: 'WASTE DISPOSAL AGENCY',
    value: 'WASTE_DISPOSAL_AGENCY'
  },
  {
    label: 'WASTE PICKER',
    value: 'WASTE_PICKER'
  },
  {
    label: 'E COMMERCE ENTITY',
    value: 'E_COMMERCE_ENTITY'
  },
  {
    label: 'OTHERS',
    value: 'OTHERS'
  }
];

export const DESTINATION_TYPE = [
  {
    label: 'PROCESSOR',
    value: 'PROCESSOR'
  },
  {
    label: 'BUYER',
    value: 'BUYER'
  },
  {
    label: 'SITE',
    value: 'SITE'
  },
  {
    label: 'OTHERS',
    value: 'OTHERS'
  }
];
