import { lazy } from 'react';

import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
const Profile = Loadable(lazy(() => import('pages/profile')));
const Tasks = Loadable(lazy(() => import('pages/tasks')));
const Tickets = Loadable(lazy(() => import('pages/tickets')));
const ContactUs = Loadable(lazy(() => import('pages/contact-us')));
const AddTask = Loadable(lazy(() => import('pages/add-task')));
const ViewTask = Loadable(lazy(() => import('pages/view-task')));
const Sites = Loadable(lazy(() => import('pages/sites')));
const ViewTicket = Loadable(lazy(() => import('pages/view-ticket')));
const TaskReport = Loadable(lazy(() => import('pages/task-report')));
const LogisticsReport = Loadable(lazy(() => import('pages/trip-report')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/dashboard',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'dashboard/profile',
      element: <Profile />
    },
    {
      path: 'dashboard/change-password',
      element: <Profile />
    },
    {
      path: 'dashboard/tasks/:status',
      element: <Tasks />
    },
    {
      path: 'dashboard/query/:status',
      element: <Tickets />
    },
    {
      path: 'dashboard/ticket/view/:id',
      element: <ViewTicket />
    },
    {
      path: 'dashboard/contact-us',
      element: <ContactUs />
    },
    {
      path: 'dashboard/task/add',
      element: <AddTask />
    },
    {
      path: 'dashboard/task/view/:id',
      element: <ViewTask />
    },
    {
      path: 'dashboard/sites',
      element: <Sites />
    },
    {
      path: 'dashboard/report',
      element: <TaskReport />
    },
    {
      path: 'dashboard/logistics',
      element: <LogisticsReport />
    }
  ]
};

export default MainRoutes;
