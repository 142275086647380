import PropTypes from 'prop-types';
import logo from 'assets/images/logo.jpg';

const Logo = ({ open }) => {
  return (
    <div className="logo-div">
      <img
        src={logo}
        alt="Enviro"
        width="100%"
        height={!open ? '60px' : 'auto'}
      />
    </div>
  );
};

Logo.propTypes = {
  open: PropTypes.bool
};

export default Logo;
